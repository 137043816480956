<template>
  <!-- 修改工资密码 -->
  <div class="resetPassword">
    <van-nav-bar title="重置工资密码"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <div>
      <!-- :rules="[{ required: true, message: '请填写原密码' }]" -->
      <van-form @submit="onSubmit">
        <van-field v-model="newPassword1"
                   required
                   readonly
                   clickable
                   type="password"
                   name="newPassword1"
                   label="新密码"
                   placeholder="新密码"
                   @touchstart.native.stop="showBoard(2)" />
        <van-number-keyboard v-model="newPassword1"
         extra-key="X"
                             :show="showBoard2"
                             :maxlength="6"
                             @blur="showBoard2 = false" />
        <van-field v-model="newPassword2"
                   required
                   readonly
                   clickable
                   type="password"
                   name="newPassword2"
                   label="确认密码"
                   placeholder="确认密码"
                   @touchstart.native.stop="showBoard(3)" />
        <van-number-keyboard v-model="newPassword2"
         extra-key="X"
                             :show="showBoard3"
                             :maxlength="6"
                             @blur="showBoard3 = false" />
        <!-- <p class="pswDesc">
          密码必须是8-16位的英文字母、数字、字符组合（不能 是纯数字）
        </p> -->
        <div style="margin: 16px;">
          <van-button round
                      block
                      type="info"
                      :disabled="!canSave"
                      native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { NumberKeyboard } from 'vant';
import { changeSalaryPwd, setSalaryPwd } from "@api/wx.js"
export default {
  data () {
    return {
      userInfo: JSON.parse(localStorage.userInfo) || {},
      newPassword1: '',
      newPassword2: '',
      showBoard2: false,
      showBoard3: false
    }
  },
  computed: {
    canSave () {
      if (this.newPassword1 && this.newPassword2 && this.newPassword1 == this.newPassword2)
      {
        return true
      } else
      {
        return false
      }
    }
  },
  methods: {
    showBoard (val) {
      if (val == 2)
      {
        this.showBoard1 = false
        this.showBoard2 = true
        this.showBoard3 = false
      } else if (val == 3)
      {
        this.showBoard1 = false
        this.showBoard2 = false
        this.showBoard3 = true
      }
    },
    onClickLeft () {
      this.$router.push("/changeSalaryPsw");
    },
    onClickRight () { },
    // 提交
    onSubmit (values) {
      const data = {
        empcode: this.userInfo.empcode,
        newpwd1: values.newPassword1,
        newpwd2: values.newPassword2,
      }
      setSalaryPwd(data).then(res => {
        if (res.iserror === '0')
        {
          Toast.success('重置成功!')
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.resetPassword {
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .van-form {
    .pswDesc {
      padding: 20px 30px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #333333;
    }
    .forgetPwd {
      text-align: right;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #1989fa;
      span {
        padding: 20px;
      }
    }
  }
}
</style>